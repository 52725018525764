<template>
  <div>
    <el-row style="background: rgb(247, 247, 247)">
      <el-col v-show="isPC" :span="8" align="left" class="welcome">
        {{ websiteInfo[0]?.name }}
      </el-col>
      <el-col :span="isPC ? 16 : 24" align="right" class="mobile-right">
        <nuxt-link :to="localePath('/')">&nbsp;{{ $t("wzsy") }}&nbsp;</nuxt-link>
        <nuxt-link v-show="!userInfo" :to="localePath('/login')">&nbsp;{{ $t("login") }}&nbsp;</nuxt-link>
        <nuxt-link v-show="!userInfo" :to="localePath('/register')">&nbsp;{{ $t("register") }}&nbsp;</nuxt-link>
        <el-dropdown v-show="userInfo" style="font-size: 1vw" @command="handleCommand">
          <span class="el-dropdown-link ">
            &nbsp;{{ $t("personCenter") }}&nbsp;<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="account">{{ $t("personCenter") }}</el-dropdown-item>
            <el-dropdown-item command="logout">{{ $t("logout") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown style="font-size: 1vw;">
          <span class="el-dropdown-link ">
            &nbsp;{{ $t("language") }}&nbsp;<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="account"><div @click="switchLanguage('enObj')">English</div></el-dropdown-item>
            <el-dropdown-item command="logout"> <div @click="switchLanguage('zhObj')">中    文</div></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </el-col>
    </el-row>
    <el-row v-show="isPC" class="main-header ">
      <!-- logo -->
      <el-col :span="12" align="center">
        <nuxt-link :to="localePath('/')">
          <img
            v-if="websiteInfo"
            alt=""
            :src="websiteInfo[0]?.image"
            class="logo"
          >
        </nuxt-link>
      </el-col>
      <el-col :span="12" align="center" />
    </el-row>
  </div>
</template>
<script>
import { logout } from '../../api/login'
import { getStore } from '../../utils/storage'
import cookie from '../../utils/cookies'

export default {
  data() {
    return {
      isPC: false,
      currentLocale: 'zhObj',
      // isNow: '',
      // search: '',
      menuShow: false,
      websiteInfo: this.$store.state.websiteInfo,
      navList: this.$store.state.navList,
      height: 0,
      userInfo: this.$store.state.userInfo
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  mounted() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    console.log('userInfo', this.userInfo)
    if (getStore('currentLocale')) {

      this.currentLocale = getStore('currentLocale')
      this.loadLanguage(this.currentLocale)
      // this.$store.dispatch('nuxtServerInit', { req: { 'headers': { 'cookie': `i18n_redirected=${this.currentLocale}` }}})
      // this.$store.dispatch('fetchTranslations', this.currentLocale)
      // this.$i18n.setLocale(this.currentLocale)
    } else {
      // this.$store.dispatch('fetchTranslations', 'zhObj')
      this.loadLanguage('zhObj')
      // this.$i18n.setLocale('zhObj')
    }
  },
  methods: {
    async switchLanguage(locale) {
      this.$i18n.setLocale(locale) // 更新当前语言环境
      await this.$store.dispatch('switchLocale', locale).then(async(res) => {
        if (process.client) {

          await this.$store.dispatch('nuxtServerInit', { req: { 'headers': { 'cookie': `i18n_redirected=${locale}` }}}).then(res=>{
            this.$router.push(this.localePath('/'))
          })
        }
      })
    },
    async loadLanguage(locale) {
      this.$i18n.setLocale(locale) // 更新当前语言环境
      await this.$store.dispatch('switchLocale', locale).then(async(res) => {
        if (process.client) {

          await this.$store.dispatch('nuxtServerInit', { req: { 'headers': { 'cookie': `i18n_redirected=${locale}` }}})
        }
      })
    },
    checkDevice() {
      if (typeof window !== 'undefined') {
        this.isPC = window.innerWidth > 768 // 根据需要调整阈值
      }
    },
    showMenu() {
      this.menuShow = true
    },
    hideMenu() {
      this.menuShow = false
    },
    handleSearch() {
      this.$router.push(this.localePath({ name: 'search', query: { search: this.search }}))
    },
    signOut() {
      // 调用退出登录
      logout().then(res => {
        this.$store.commit('SIGN_OUT')
        this.userInfo = ''
        this.$router.push(this.localePath('/'))
        if (this.$route.path.includes('account')) {
          this.$router.push(this.localePath({ name: 'login' }))
        } else {
          // window.location.reload()
          this.$router.push('/');
        }
      })
    },
    login() {
      this.$store.commit('SET_TEMPORARYURL')
      this.$router.push({ name: 'login' })
    },
    register() {
      this.$router.push(this.localePath({ name: 'login', query: { tab: 2 }}))
    },
    // 处理下拉菜单选项
    handleCommand(command) {
      if (command === 'account') {
        this.$router.push(this.localePath({ name: 'account' }))
      } else if (command === 'logout') {
        this.signOut()
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
  @media (max-width: 768px) {
    .welcome{
      font-size: 3vw !important;
    }
    .mobile-right{
      font-size: 4vw !important;
      text-align: center;
    }
    .main-header {
      background-image:url("~/assets/image/LOGO图.jpg");
      .logo {
        width: 100% !important;
      }
    }
    .el-dropdown-link {
      font-size: 4vw !important;
    }
    .el-icon-arrow-down {
      font-size: 4vw !important;
    }
  }

  .mobile-right{
    padding:0.5vw 1vw;
    font-size: 1vw;
  }

.welcome{
  padding: 0.5vw;
  font-size: 1vw;
}
.h_header {
  background: #fff;

  .el-dropdown-link {
    font-size: 1vw;
    cursor: pointer;
    // color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 1vw;
  }

}

.main-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-image:url("~/assets/image/LOGO图.jpg");
  //background-size: contain; /* 图片完整显示，可能不会覆盖整个元素 */
  background-size: cover; /* 图片居中显示 */
  background-repeat: no-repeat; /* 不重复图片 */
  width: 100%; /* 容器宽度为100% */
  height: auto;
  .logo {
    padding: 3vw;
    width: 60%;
    height: auto
  }
}

.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 4.5vw;
  background: #009173;
}

////new-start
:deep(.el-input__inner) {
  height: 4.5vw;
  border-radius: 10vw;
  border: 0.2vw solid #009173;
  font-size: 1.5vw;
}

:deep(.el-input) {
  width: 70%;
}

:deep(.el-input__icon) {
  font-size: 2.5vw;
  color: white;
  width: 7vw;
  height: 3.85vw;
  //padding: 0.18vw;
  border-radius: 10vw;
  background-image: linear-gradient(to bottom, rgb(29, 115, 180), #009173);
  // background: #009173;
  line-height: 3.85vw;
  cursor: pointer;
}

:deep(.el-input__prefix) {
  //height: 3vw;
  left: 27.235vw;
  padding: 0.3vw 0;
}

.mainNav {
  .subnav {
    display: none;
    position: relative;
    left: 0;
    background: #fff;
    color: #ccc; //width: 6.95vw;
    overflow: hidden;
    //border: 1px solid #009176;
    border-top: none;

    li {
      text-align: center;
      line-height: 4vw;

      &:hover {
        background: #009176;

        a {
          color: white !important;
        }
      }
    }
  }

  &:hover {
    background: #009176;
    text-decoration: none;

    .subnav {
      display: block;
      z-index: 55;

      li {
        //background: #fff;
        font-size: 1.2vw;
      }
    }
  }
}
</style>
